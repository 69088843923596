import { LoginApiInstance, DefaultApiInstance } from "../index";
import qs from "qs";

export const AdminApi = {
  getFilter() {
    const filters = [
      {
        type: "null",
        key: "deleted_at",
      },
    ];

    const params = qs
      .stringify({ filters })
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]");

    return "&" + params;
  },

  getUsers(role) {
    let url = `/${role}/users?with[]=roles`;

    url += this.getFilter();
    return DefaultApiInstance.get(url);
  },

  getSearchedUsers(role, searchQuery) {
    let url = `/${role}/users?with[]=roles&search=${searchQuery}`;
    url += this.getFilter();
    return DefaultApiInstance.get(url);
  },

  getUser(id, role = "admin") {
    const url = `/${role}/users/${id}?with[]=managers&with[]=supervisors&with[]=roles&with[]=to&with[]=toSupervisors`;
    return DefaultApiInstance.get(url);
  },

  getRegions(role) {
    const url = `/${role}/regions?sort=order|desc`;
    return DefaultApiInstance.get(url);
  },

  getSearchedRegions(role, search) {
    const url = `/${role}/regions?search=${search}`;
    return DefaultApiInstance.get(url);
  },

  getAllRegions(role, qty) {
    const url = `/${role}/regions?per_page=${qty}&sort=name|asc`;
    return DefaultApiInstance.get(url);
  },
  getUsersByRole(role) {
    const url = `/admin/users/by-role?roles[]=${role}&per_page=1000`;
    return DefaultApiInstance.get(url);
  },
  updateUser(id, data) {
    const url = `/admin/users/${id}`;
    return DefaultApiInstance.put(url, data);
  },
  removeUser(id) {
    const url = `/admin/users/${id}`;
    return DefaultApiInstance.delete(url);
  },

  createUser(data) {
    const url = `admin/users`;

    return DefaultApiInstance.post(url, data);
  },
};
